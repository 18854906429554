@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Pretendard' sans-serif;
    background-color: black;
    color: white;
    box-sizing: border-box;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

@layer components {
  .on-modal-back {
    overflow-y: hidden;
  }

  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar:-webkit-scrollbar {
    display: none;
  }

  .top-button {
    position: fixed;
    text-align: center;
    right: 40px;
    bottom: 30px;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border: 1px solid #CEA57E;
    color: #CEA57E;
    font-size: 18px;
    font-weight: 300;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transition: all 0.4s;
  }

  .show-top-button {
    opacity: 1;
    cursor: pointer;
  }

  .hide-top-button {
    opacity: 0;
    cursor: default;
  }

  @media (max-width: 1280px) {
    
    .top-button {
      right: 30px;
      bottom: 20px;
    }
  }

  @media (max-width: 613px) {

    .top-button {
      display: none;
      /* right: 15px;
      bottom: 15px;
      width: 45px;
      height: 45px;
      line-height: 40px; */
    }
  }

  /* .top-button:hover {
    background-color: #CEA57E;
    color: black;
  } */
}